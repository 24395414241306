import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toogleLanguage } from '../../locales/i18n';
import { useSelector } from 'react-redux';
import { Typography, AppBar, Select, MenuItem, Toolbar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { changeLanguage } from '../../utils/helper';
import { SwitchLanguage } from './styled';
import { EnglishFlag, ItalianFlag } from '../../assets/icon';

const HeaderComponent = () => {
	const navigate = useNavigate();
	let lang = localStorage.getItem('language');

	if (!lang) {
		// If language is not set in localStorage, try to detect the browser's language
		const browserLang = navigator.language || navigator.userLanguage;
		lang = browserLang === 'it' ? 'it' : 'en';
		changeLanguage(lang);
	} else if (lang !== 'it') {
		// If language is set in localStorage but it's not 'it', default to 'en'
		lang = 'en';
	}
	document.documentElement.lang = lang;
	const { company } = useSelector(state => state.company);
	const handleChangeLanguage = (event) => {
		const lang = event.target.value;
		localStorage.setItem('language', lang);
		toogleLanguage(lang);
		document.documentElement.lang = lang;
	};
	return (
		<AppBar component="nav" color='transparent' position="static">
			<Toolbar>
				<Typography
					className='headerTitle'
					//onClick={() => navigate(`/${company?.unique_code}/ ? wwww.quotabus.com`)}
					onClick={() => {
						if (company?.name) {
							navigate(`/${company.unique_code}/`);
						} else {
							window.open('https://quotabus.com/', '_blank');
						}
					}}
					component="div"
					variant="h6"
					sx={{ my: 1, mx: 2, fontSize: '30px', fontWeight: '500', color: '#000', flexGrow: 1 }}>{company?.name || 'Quotabus'}</Typography>
				<SwitchLanguage>
					<Select
						displayEmpty
						inputProps={{ 'aria-label': 'Without label' }}
						value={localStorage.getItem('language')}
						onChange={handleChangeLanguage}
						className='flag_dropdown'
						IconComponent={() => (
							<ArrowDropDownIcon className='flagIcon' />
						)}
						MenuProps={{
							PaperProps: {
								sx: {
									top: '40px !important',
									'@media (max-width: 991px)': {
										top: '40px !important',
									}
								},
							},
						}}
					>
						<MenuItem className='flag_icon' value='it'><Typography component='p'>IT</Typography> <ItalianFlag></ItalianFlag></MenuItem>
						<MenuItem className='flag_icon' value='en'><Typography component='p'>EN</Typography> <EnglishFlag></EnglishFlag></MenuItem>
					</Select>
				</SwitchLanguage>
			</Toolbar>
		</AppBar>

	);
};

export default HeaderComponent;
