import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../utils/helper.js';
import { Container, Typography } from '@mui/material';
import { Title, MainBox, CheckMark, HeadingWithIcon } from './styled.js';
import { CheckmarkIcon } from '../../assets/icon/index.js';

const PaymentSuccess = () => {
	const { t } = useTranslation();
	useEffect(() => {
		changeLanguage('en'); //for now only test in EN
	});
	return (
		<Container>
			<MainBox>
				<Title>
					<HeadingWithIcon>
						<CheckMark>
							<CheckmarkIcon />
						</CheckMark>
						<Typography variant="h1" component="h1">{t('PaymentSuccess.Title')}</Typography>
					</HeadingWithIcon>
					<Typography variant="h1" component="p">{t('PaymentSuccess.Detail')}</Typography>
				</Title>
			</MainBox>
		</Container >
	);
};

export default PaymentSuccess;
