import { createSlice } from '@reduxjs/toolkit';
import { acceptRequest, buspartnerConfirmation, clientConfirmation, getRequestClientType, rejectRequest } from '../email';

const initialState = {
	loading : false,
	acceptRequestDetail : false,
	acceptRequestError : false,
	rejectRequestDetail : false,
	rejectRequestError : false,
	clientConfirmDetail : false,
	clientConfirmError : false,
	requestClientTypeDetail : {},
	requestClientTypeError : false,
	buspartnerConfirmDetail: false,
	buspartnerConfirmError: false,
};

const emailSlice = createSlice({
	name: 'email',
	initialState,
	reducers: {
		reset: () => initialState,
		resetAcceptRequest: (state) => {
			state.acceptRequestDetail = false;
			state.acceptRequestError = false;
		},
		resetRejectRequest: (state) => {
			state.rejectRequestDetail = false;
			state.rejectRequestError = false;
		},
		resetClientConfirm: (state) => {
			state.clientConfirmDetail = false;
			state.clientConfirmError = false;
		},
		resetBuspartnerConfirmation: (state) => {
			state.buspartnerConfirmDetail = false;
			state.buspartnerConfirmError = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(acceptRequest.pending, (state) => {
			state.loading = true;
			state.acceptRequestDetail = false;
			state.acceptRequestError = false;
		});
		builder.addCase(acceptRequest.fulfilled, (state) => {
			state.loading = false;
			state.acceptRequestDetail = true;
		});
		builder.addCase(acceptRequest.rejected, (state) => {
			state.loading = false;
			state.acceptRequestError = true;
		});

		builder.addCase(rejectRequest.pending, (state) => {
			state.loading = true;
			state.rejectRequestDetail = false;
			state.rejectRequestError = false;
		});
		builder.addCase(rejectRequest.fulfilled, (state) => {
			state.loading = false;
			state.rejectRequestDetail = true;
		});
		builder.addCase(rejectRequest.rejected, (state) => {
			state.loading = false;
			state.rejectRequestError = true;
		});

		builder.addCase(clientConfirmation.pending, (state) => {
			state.loading = true;
			state.clientConfirmDetail = false;
			state.clientConfirmError = false;
		});
		builder.addCase(clientConfirmation.fulfilled, (state) => {
			state.loading = false;
			state.clientConfirmDetail = true;
		});
		builder.addCase(clientConfirmation.rejected, (state) => {
			state.loading = false;
			state.clientConfirmError = true;
		});

		builder.addCase(getRequestClientType.pending, (state) => {
			state.loading = true;
			state.requestClientTypeDetail = {};
			state.requestClientTypeError = false;
			state.clientConfirmDetail = false;
			state.clientConfirmError = false;
		});
		builder.addCase(getRequestClientType.fulfilled, (state, action) => {
			state.loading = false;
			state.requestClientTypeDetail = action.payload?.response.data.data;
		});
		builder.addCase(getRequestClientType.rejected, (state) => {
			state.loading = false;
			state.requestClientTypeError = true;
		});

		builder.addCase(buspartnerConfirmation.pending, (state) => {
			state.loading = true;
			state.buspartnerConfirmDetail = false;
			state.buspartnerConfirmError = false;
		});
		builder.addCase(buspartnerConfirmation.fulfilled, (state) => {
			state.loading = false;
			state.buspartnerConfirmDetail = true;
		});
		builder.addCase(buspartnerConfirmation.rejected, (state) => {
			state.loading = false;
			state.buspartnerConfirmError = true;
		});
	},
});

export const { reset, resetAcceptRequest, resetRejectRequest, resetClientConfirm, resetBuspartnerConfirmation } = emailSlice.actions;

export default emailSlice.reducer;
