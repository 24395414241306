import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckmarkIcon } from '../../assets/icon/index.js';
import { resetBuspartnerConfirmation } from '../../redux/email/slice.js';
import { buspartnerConfirmation } from '../../redux/email/index.js';
import { changeLanguage } from '../../utils/helper.js';
import { Title, MainBox, CheckMark, HeadingWithIcon, CircluarBox } from './styled.js';

const BusPartnerConfirmation = () => {
	const { t } = useTranslation();
	const { loading, buspartnerConfirmDetail, buspartnerConfirmError } = useSelector(state => state.email);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { requestId } = useParams();
	useEffect(() => {
		changeLanguage('it'); // for now bus partners are all italians
		if (requestId) {
			dispatch(buspartnerConfirmation({ requestId }));
		}
		return () => {
			dispatch(resetBuspartnerConfirmation());
		};
	}, [requestId]);

	useEffect(() => {
		if (buspartnerConfirmError) {
			navigate('/pagenotfound');
		}

	}, [buspartnerConfirmError]);

	return (
		<React.Fragment>
			<Container>
				{buspartnerConfirmDetail &&
					<MainBox>
						<Title>
							<HeadingWithIcon>
								<CheckMark>
									<CheckmarkIcon />
								</CheckMark>
								<Typography variant="h1" component="h1">{t('BusPartnerConfirmation.Title')}</Typography>
							</HeadingWithIcon>
							<Typography variant="h1" component="p">{t('BusPartnerConfirmation.Detail')}</Typography>
						</Title>
					</MainBox>}
				{loading &&
					<CircluarBox>
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<CircularProgress />
						</Box>
					</CircluarBox>}
			</Container>
		</React.Fragment>
	);
};

export default BusPartnerConfirmation;
