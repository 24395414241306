import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.json';
import translationIT from './it.json';

// the translations
const resources = {
	it: {
		translation: translationIT
	},
	en: {
		translation: translationEN
	},
};

const defaultLang = localStorage.getItem('language');
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: defaultLang || 'it',
		// keySeparator: false, // we do not use keys in form messages.welcome
		fallbackLng: defaultLang || 'it',
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});


export const translate = (text) => {
	return i18n.t(text);
};

export const toogleLanguage = (lang) => {
	i18n.changeLanguage(lang);
};

export default i18n;