import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPostAPI, callGetAPI } from '../../services/auth';

export const getConfig = createAsyncThunk(
	'getConfig',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'configuration', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateConfig = createAsyncThunk(
	'updateConfig',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'configuration', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);