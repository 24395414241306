import styled from '@emotion/styled';

export const ContentBox = styled.div`
    min-height: calc(100vh - 300px);
    padding-top: 20px;
    @media(max-width: 1200px) {
        padding-top: 40px;
        min-height: calc(100vh - 210px);
    }
    @media (max-width: 767px) {
        margin-top: 30px;
        padding-top: 0;
    }
`;

export const CustomContainer = styled.div`
    max-width: calc(100% - 265px);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1100px) {
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
`;