import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: 'var(--SfProLightFonts)',
		h1: {
			fontFamily: 'var(--NanumMyeongjoFonts)',
			fontSize: '60px',
			fontWeight: 300,
		},
		h2: {
			fontFamily: 'var(--NanumMyeongjoFonts)',
			fontSize: '44px',
			fontWeight: 300,
		},
		h3: {
			fontFamily: 'var(--NanumMyeongjoFonts)',
			fontSize: '32px',
			fontWeight: 300,
		},
		h4: {
			fontFamily: 'var(--NanumMyeongjoFonts)',
			fontWeight: 300,
		},
		h5: {
			fontSize: '24px',
			fontFamily: 'var(--NanumMyeongjoFonts)',
			fontWeight: 300,
		},
		h6: {
			fontSize: '18px',
			fontFamily: 'var(--NanumMyeongjoFonts)',
			fontWeight: 300,
		},
	},
	palette: {
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#132356',
			light: '#FFCA78',
		},
		background: {
			paper: '#f7f7f7',
			default: '#ffffff',
		},
		text: {
			primary: '#000000',
			secondary: '#cccccc',
			disabled: '#D9D9D9',
		},
		info: {
			main: '#fff7ea',
			light: '#FFDCA6',
		},
		error: {
			main: '#FF0000'
		}
	},
});

export default theme;
