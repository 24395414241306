import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPostAPI, userSignUp } from '../../services/auth';

export const handleLogin = createAsyncThunk(
	'handleLogin',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'login', body: params.body, isAuthenticated: false });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogin();
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const handleSignup = createAsyncThunk(
	'handleSignup',
	async(params, { rejectWithValue }) => {
		const response = await userSignUp({ route: 'register/bus-partner', body: params.body });
		if (!response) return rejectWithValue();
		return { response };
	},
);

export const refreshToken = async() => {
	const authToken = localStorage.getItem('authToken');
	const authTokenAdmin = localStorage.getItem('authTokenAdmin');
	if (authToken !== '' && authTokenAdmin !=='') return false;
	const response = await callPostAPI({ route: 'refresh-token',  isAdmin : authTokenAdmin ? true : false });
	return response;
};

export const handleLogOut = createAsyncThunk(
	'handleLogOut',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'logout', body: params });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogout();
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const handleAdminLogOut = createAsyncThunk(
	'handleAdminLogOut',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'logout', body: params, isAdmin:true });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogout();
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const forgotPassword = createAsyncThunk(
	'forgotPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'forget-password', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const ResetPassword = createAsyncThunk(
	'ResetPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'reset-password', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const acceptTerms = createAsyncThunk(
	'acceptTerms',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'accept-terms', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);