import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const Header = styled.div`
  margin: 33px 30px 0 46px;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 50px;
  padding: 12px 20px;
  ${theme.breakPoint.tablet} {
    padding: 10px 20px;
    margin: 17px 15px 0 23px;
  }
  svg {
    width: 180px;
    ${theme.breakPoint.tablet} {
      width: 140px;
    }
  }
`;

export const HeaderLogo = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction:row;
    align-items: center;
   
        @media (max-width:567px){
          .headerTitle{
            font-size:30px !important;
          }
        }

    svg {
        width: 164px;
        padding-left: 13px;

        path {
            fill: ${theme.colors.white};

            &:last-child {
                fill: ${theme.colors.lightGreen};
            }
        }
    }
  
    @media (max-width:768px){
      flex-direction:column;
    }
    

`;

export const SwitchLanguage = styled.div`
    .MuiOutlinedInput-root{
        width:106px;
        height:43px;
        border-radius:26px;
        flex-direction: row-reverse;
        border: 1px solid ${theme.colors.black};
        svg {
            margin-left: 5px;
            width: 25px;
            height: 25px;
            &.flagIcon path {
                fill: #677489;
            }
        }
        .MuiSelect-select {
            padding: 0;
            display: flex;
            align-items: center;
            padding-right: 0 !important;
            p{
              top: 2px;
              position: relative;
            }
        }
        &:focus-visible {
            outline: none !important;
        }
        &:hover fieldset {
            border: none
        }
        &.Mui-focused fieldset{
            border: none !important;
        }
    }
`;