import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckmarkIcon } from '../../assets/icon/index.js';
import { resetAcceptRequest } from '../../redux/email/slice.js';
import { acceptRequest } from '../../redux/email/index.js';
import { changeLanguage } from '../../utils/helper.js';
import { Title, MainBox, CheckMark, HeadingWithIcon, CircluarBox } from './styled.js';

const BusPartnerAcceptRequest = () => {
	const { t } = useTranslation();
	const { loading, acceptRequestDetail, acceptRequestError } = useSelector(state => state.email);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { requestId } = useParams();
	useEffect(() => {
		changeLanguage('it'); // for now bus partners are all italians
		if (requestId) {
			dispatch(acceptRequest({ requestId }));
		}
		return () => {
			dispatch(resetAcceptRequest());
		};
	}, [requestId]);

	useEffect(() => {
		if (acceptRequestError) {
			navigate('/pagenotfound');
		}

	}, [acceptRequestError]);

	return (
		<React.Fragment>
			<Container>
				{acceptRequestDetail &&
					<MainBox>
						<Title>
							<HeadingWithIcon>
								<CheckMark>
									<CheckmarkIcon />
								</CheckMark>
								<Typography variant="h1" component="h1">{t('BusPartnerAcceptRequest.Title')}</Typography>
							</HeadingWithIcon>
							<Typography variant="h1" component="p">{t('BusPartnerAcceptRequest.Detail')}</Typography>
						</Title>
					</MainBox>}
				{loading &&
					<CircluarBox>
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<CircularProgress />
						</Box>
					</CircluarBox>}
			</Container>
		</React.Fragment>
	);
};

export default BusPartnerAcceptRequest;
