import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { Title, MainBox, HeadingWithIcon, CheckMarkRed } from './styled.js';
import { CrossIcon } from '../../assets/icon/index.js';

const PaymentCancel = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<MainBox>
				<Title>
					<HeadingWithIcon>
						<CheckMarkRed>
							<CrossIcon />
						</CheckMarkRed>
						<Typography variant="h1" component="h1">{t('PaymentCancel.Title')}</Typography>
					</HeadingWithIcon>
					<Typography variant="h1" component="p">{t('PaymentCancel.Detail')}</Typography>
				</Title>
			</MainBox>
		</Container >
	);
};

export default PaymentCancel;
