import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const MainBox = styled.div`
max-width: 1024px;
margin: 139px auto 40px;
border-radius: 11px;
  border: 1px solid ${theme.colors.grey};
  box-sizing: border-box;
  ${theme.breakPoint.tablet} {
    margin: 40px auto 40px;
    border-radius: 30px;
    padding: 0 20px 30px;
  }
`;
export const CircluarBox = styled.div`
max-width: 1024px;
margin: 139px auto 40px;
border-radius: 11px;
  // border: 1px solid ${theme.colors.grey};
  box-sizing: border-box;
  ${theme.breakPoint.tablet} {
    margin: 40px auto 40px;
    border-radius: 30px;
    padding: 0 20px 30px;
  }
`;
export const Title = styled.div`
  padding: 89px 0;
  text-align: center;
  ${theme.breakPoint.tablet} {
    padding: 40px 0 20px;
  }
  h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.8px;
    color: ${theme.colors.black};
    padding-left: 15px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: ${theme.colors.black};
    max-width: 810px;
    margin: 0 auto;
  }
`;
export const CheckMark = styled.div`
  width: 30px;
  height: 30px;
  background: ${theme.colors.green};
  border-radius: 100px;
  display:flex;
  align-items: center;
  justify-content: center;
`;
export const CheckMarkRed = styled.div`
  width: 30px;
  height: 30px;
  background: ${theme.colors.lightRed};
  border-radius: 100px;
  display:flex;
  align-items: center;
  justify-content: center;
`;
export const HeadingWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  .requestSuccessModal{
    dispaly:flex !important;
    flex-direction:row;
  }
   @media (max-width:768px){
      flex-direction:column !important;
    }
`;