import React, { useEffect } from 'react';
import HeaderComponent from '../HeaderComponent';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBusPartnerDetail } from '../../redux/company';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentBox, CustomContainer } from './styled';
import { resetCompany } from '../../redux/company/slice';

const LayoutComponent = ({ children }) => {
	const { t } = useTranslation();
	const { companyError } = useSelector(state => state.company);
	const label = (
		<span>
			{t('Footer.PoweredBy')}{' '}
			<a>
				{`${t('General.Quotabus')}`}<span>.</span>
			</a>
		</span>
	);

	const handleClick = () => {
		window.open('https://quotabus.com/', '_blank', 'noopener,noreferrer');
	};
	const { busPartnerCode } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (busPartnerCode) {
			dispatch(getBusPartnerDetail({ unique_code: busPartnerCode }));
		}
	}, []);
	useEffect(() => {
		if (companyError) {
			navigate('/');
		}
		return () => {
			dispatch(resetCompany());
		};
	}, [companyError]);

	return (
		<>
			<HeaderComponent />
			<CustomContainer>
				<ContentBox>
					{children}
				</ContentBox>
				<div className="footer-part">
					<Chip
						label={label}
						color="primary"
						clickable
						onClick={handleClick}
						style={{ cursor: 'pointer' }}
						variant="outlined"
					/>
				</div>
			</CustomContainer>
		</>
	);
};

export default LayoutComponent;