import React, { useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshToken } from '../redux/auth';
import { AuthContext } from '../utils/helper';
import { loggedUserInfo, reset } from '../redux/auth/slice';
import { useDispatch } from 'react-redux';
const AuthProvider = ({ children }) => {
	const [isLoggedInSuccess, setIsLoggedIn] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	const handleStorageChange = (event) => {
		if (event.key === 'authToken' && event.newValue === null) {
			handleLogout();
		}
	};
	useEffect(() => {
		const loadAPI = async() => {
			const res = await refreshToken();
			if (res && !res.data.error) {
				setIsLoggedIn(true);
				const payload = res.data.data;
				dispatch(loggedUserInfo(payload));
				if (payload.role == 'Admin') {
					navigate('/');
					return;
				}
				// localStorage.setItem('authToken', payload && payload.access_token);
				const origin = location.pathname || '/';
				navigate(origin);
				setIsLoading(false);
				return;
			}
			setIsLoggedIn(false);
			setIsLoading(false);
		};
		loadAPI();
	}, []);

	const handleLogin = async() => {
		setIsLoggedIn(true);
		const origin = location.pathname || '/';
		navigate(origin);
	};

	const handleLogout = () => {
		setIsLoggedIn(null);
		dispatch(reset());
		localStorage.removeItem('authToken');
		localStorage.removeItem('authTokenAdmin');
		navigate('/login');
	};

	const value = {
		isLoggedInSuccess,
		isLoading,
		onLogin: handleLogin,
		onLogout: handleLogout,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: node.isRequired,
};

export default AuthProvider;
