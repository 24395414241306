import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestClientType } from '../../redux/email/index.js';
import { resetClientConfirm } from '../../redux/email/slice.js';
import { useNavigate, useParams } from 'react-router-dom';
import PrivateInformation from '../client-information/private.js';
import CompanyInformation from '../client-information/company.js';
import { changeLanguage } from '../../utils/helper.js';

const CustomerAcceptRequest = () => {
	const { clientConfirmDetail, requestClientTypeDetail } = useSelector(state => state.email);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { requestId, busPartnerCode } = useParams();
	useEffect(() => {
		if (requestId) {
			dispatch(getRequestClientType({ requestId }));
		}
		return () => {
			dispatch(resetClientConfirm());
		};
	}, [requestId]);

	useEffect(() => {
		if (clientConfirmDetail) {
			navigate('/'+busPartnerCode + '/client-confirmed/' + requestId );
		}
	}, [clientConfirmDetail]);

	useEffect(() => {
		if (requestClientTypeDetail?.communication_language) {
			changeLanguage(requestClientTypeDetail?.communication_language);
		}
	}, [requestClientTypeDetail]);

	return (
		<Container>
			{!isEmpty(requestClientTypeDetail) && requestClientTypeDetail?.client_type === 'Private' &&
				<PrivateInformation requestId={requestId}></PrivateInformation>
			}
			{!isEmpty(requestClientTypeDetail) && requestClientTypeDetail?.client_type === 'Company' &&
				<CompanyInformation requestId={requestId}></CompanyInformation>
			}
		</Container >
	);
};

export default CustomerAcceptRequest;
